/* eslint-disable import/no-anonymous-default-export */
export default {
    Experiences: [
        {
            company: "Raytheon (RTX)",
            title: "Software Engineer I @",
            duration: "April 2023 - Present",
            desc: [
                "Developed a Python test bench to automate API/Gateway execution improving test efficiency by 30%",
                "Integrated multi-threading to reduce execution times by 21% and improved protocol message tracking",
                "Refactored an outdated C++ code generator tool into Python, enhancing performance and streamlining the application's overall design from 20 + files down to 7",
                "Automated scenario-based testing with Jenkins for simulations involving hardware and real-time data",
                "Improved software stability by resolving 5+ critical errors using unit tests and GDB debugging",
                "Conducted code reviews with teams of 6+ members, ensuring coding standards and best practices",
                "Debugged and created MATLAB, Perl, Shell, and Lua scripts for resolving issues and new functionalities",
                "Collaborated with cross-functional teams to ensure seamless integration of critical software updates"
            ]
        },
        {
            company: "Infinite Options",
            title: "Software Engineer Intern @",
            duration: "October 2022 - December 2022",
            desc: [
                "Implemented payment service APIs (Apple Pay, Venmo, PayPal) with JavaScript for payment processing",
                "Redesigned client-facing web pages with React, improving user experience and lowering transaction drop - off rates by 10%",
                "Optimized AWS Lambda usage, reducing API call frequency and decreasing operational costs by 20%",
                "Designed a web-scraping algorithm with Cheerio API to automate content updates from CNN blogs",
                "Leveraged SSL certificates to securely transfer transaction data between Apple Pay servers and Flask backend API when validating merchant requests"
            ]
        },
        {
            company: "Meta",
            title: "Security Officer @",
            duration: "June 2021 - Present",
            desc: [
                "Monitored and maintained security protocols throughout the headquarters campus",
                "Escalated/de-escalated situations pertaining to the policies of the client",
                "Provided customer service to clients by carrying out safety and security procedures, such as emergency response activities",
                "Responded to incidents and critical situations in a calm, problem solving manner"
            ]
        },
        {
            company: "Chipotle",
            title: "Kitchen Manager @",
            duration: "May 2018 - June 2021",
            desc: [
                "Ensured food quality by cooking and prepping food to order, and following kitchen procedures",
                "Monitored food waste and inventory levels, as well as resolving food quality issues",
                "Certified that the kitchen and crew members met the guidelines to ensure there are no injuries or accidents",
                "Filling out Material Safety Data Sheets (MSDS) and Inventory Rotation (FIFO)"
            ]
        },
        {
            company: "Panera",
            title: "Line Crew @",
            duration: "September 2017 - May 2018",
            desc: [
                "Ensured every order is made quickly, correctly, and consistently",
                "Met speed and accuracy goals of the store",
                "Stepped in and supported my team and manager when appropriate",
                "Assembled a wide range of menu items"
            ]
        },
        {
            company: "Claim Jumper",
            title: "Lead Host/Runner @",
            duration: "July 2016 - September 2017",
            desc: [
                "Kept the flow of the restaurant at a steady pace by directing guests to tables and the bar",
                "Welcomed guests as they enter the restaurant, seated them, and helped ensure quality of product and service in an efficient manner",
                "Helped customers with orders by explaining dishes and drinks and making recommendations as appropriate",
                "Coordinated food orders to guarantee timely and efficient delivery to guests"
            ]
        }
    ]
}